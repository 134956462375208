<template>
  <div id="disinfectant">
    <el-dialog
      :title="disinfectantFormTitle"
      width="680px"
      :visible.sync="disinfectantDialogVisible"
      :close-on-click-modal="false"
      @close="disinfectantDialogClose"
    >
      <el-form
        ref="disinfectantFormRef"
        :model="disinfectantForm"
        :rules="disinfectantFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="日期" prop="collectionDate">
              <el-date-picker v-model="disinfectantForm.collectionDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="名称" prop="name">
              <el-input v-model="disinfectantForm.name" placeholder="请输入名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数量" prop="quantity">
              <el-input v-model="disinfectantForm.quantity" placeholder="请输入数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="有效期至" prop="validDate">
              <el-date-picker v-model="disinfectantForm.validDate" placeholder="请选择有效期至" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="领用量" prop="collectionQuantity">
              <el-input v-model="disinfectantForm.collectionQuantity" placeholder="请输入领用量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="领用人" prop="collector">
              <el-input v-model="disinfectantForm.collector" placeholder="请输入领用人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="去向" prop="whereabouts">
              <el-input v-model="disinfectantForm.whereabouts" placeholder="请输入去向" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结存量" prop="balanceQuantity">
              <el-input v-model="disinfectantForm.balanceQuantity" placeholder="请输入结存量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="复核人" prop="reviewer">
              <el-input v-model="disinfectantForm.reviewer" placeholder="请输入复核人" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="disinfectantDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="disinfectantFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="名称">
        <el-input v-model="searchForm.name" placeholder="请输入名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="disinfectantPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.collectionDate">{{ scope.row.collectionDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column label="有效期至">
        <template slot-scope="scope">
          <span v-if="scope.row.validDate">{{ scope.row.validDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="collectionQuantity" label="领用量" />
      <el-table-column prop="collector" label="领用人" />
      <el-table-column prop="whereabouts" label="去向" />
      <el-table-column prop="balanceQuantity" label="结存量" />
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="disinfectantPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addDisinfectant, deleteDisinfectant, updateDisinfectant, selectDisinfectantInfo, selectDisinfectantList } from '@/api/storage/disinfectant'

export default {
  data () {
    return {
      disinfectantDialogVisible: false,
      disinfectantFormTitle: '',
      disinfectantForm: {
        id: '',
        collectionDate: '',
        name: '',
        quantity: '',
        validDate: '',
        collectionQuantity: '',
        collector: '',
        whereabouts: '',
        balanceQuantity: '',
        reviewer: ''
      },
      disinfectantFormRules: {
        name: [{ required: true, message: '名称不能为空', trigger: ['blur', 'change']}]
      },
      disinfectantPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      }
    }
  },
  created () {
    selectDisinfectantList(this.searchForm).then(res => {
      this.disinfectantPage = res
    })
  },
  methods: {
    disinfectantDialogClose () {
      this.$refs.disinfectantFormRef.resetFields()
    },
    disinfectantFormSubmit () {
      if (this.disinfectantFormTitle === '消毒剂领用详情') {
        this.disinfectantDialogVisible = false
        return
      }
      this.$refs.disinfectantFormRef.validate(async valid => {
        if (valid) {
          if (this.disinfectantFormTitle === '新增消毒剂领用') {
            await addDisinfectant(this.disinfectantForm)
          } else if (this.disinfectantFormTitle === '修改消毒剂领用') {
            await updateDisinfectant(this.disinfectantForm)
          }
          this.disinfectantPage = await selectDisinfectantList(this.searchForm)
          this.disinfectantDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.disinfectantFormTitle = '新增消毒剂领用'
      this.disinfectantDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteDisinfectant(row.id)
        if (this.disinfectantPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.disinfectantPage = await selectDisinfectantList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.disinfectantFormTitle = '修改消毒剂领用'
      this.disinfectantDialogVisible = true
      this.selectDisinfectantInfoById(row.id)
    },
    handleInfo (index, row) {
      this.disinfectantFormTitle = '消毒剂领用详情'
      this.disinfectantDialogVisible = true
      this.selectDisinfectantInfoById(row.id)
    },
    selectDisinfectantInfoById (id) {
      selectDisinfectantInfo(id).then(res => {
        this.disinfectantForm.id = res.id
        this.disinfectantForm.collectionDate = res.collectionDate
        this.disinfectantForm.name = res.name
        this.disinfectantForm.quantity = res.quantity
        this.disinfectantForm.validDate = res.validDate
        this.disinfectantForm.collectionQuantity = res.collectionQuantity
        this.disinfectantForm.collector = res.collector
        this.disinfectantForm.whereabouts = res.whereabouts
        this.disinfectantForm.balanceQuantity = res.balanceQuantity
        this.disinfectantForm.reviewer = res.reviewer
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectDisinfectantList(this.searchForm).then(res => {
        this.disinfectantPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectDisinfectantList(this.searchForm).then(res => {
        this.disinfectantPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectDisinfectantList(this.searchForm).then(res => {
        this.disinfectantPage = res
      })
    }
  }
}
</script>

<style>
</style>
